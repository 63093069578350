import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { skills } from "../data/skills";
import SkillCard from "./SkillCard";
import { useTranslation } from "react-i18next";
import TrackVisibility from "react-on-screen";

export const Skills = () => {
  const { t } = useTranslation();

  return (
    <section className="skill" id="skills">
      <Container>
        <Col>
          <Row>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <h2>{t("skills.heading")}</h2>
                </div>
              )}
            </TrackVisibility>
          </Row>
          <Row>
            <Col>
              <div className="skill-bx">
                <div className="skill-cards">
                  {skills.map((skill) => {
                    return <SkillCard key={`${skill.name}`} {...skill} />;
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Container>
    </section>
  );
};
