import React, { useRef } from "react";
import TrackVisibility from "react-on-screen";
import { Container, Col } from "react-bootstrap";
import { myProjects } from "../data/projects.js";
import { useTranslation } from "react-i18next";
import Tab from "./Tab";
import ProjectContent from "./ProjectContent";

export const Projects = () => {
  const { t } = useTranslation();
  const projectsSectionRef = useRef(null);
  
  return (
    <section
      ref={projectsSectionRef}
      className="project"
      id="projects"
      >
      <Container>
        <Col style={{ marginBottom: "10%" }}>
          <div>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  style={{
                    marginBottom: "5%",
                  }}
                  >
                  <h2>{t("projects.heading")}</h2>
                </div>
              )}
            </TrackVisibility>
          </div>
          <div
            style={{
              width: "100%",
            }}>
            {myProjects.map((project, index) => {
              return (
                <Tab projectsSectionRef={projectsSectionRef} key={index} index={index}>
                  <ProjectContent key={index} {...project} />
                </Tab>
              )
            })}
          </div>
        </Col>
      </Container>
    </section>
  );
};
