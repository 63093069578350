import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";

const ProjectCardImage = ({ image, imageIndex, projectNumber }) => {
  const [showTwo, setShowTwo] = useState(false);
  //   const [rotationAngle, setRotationAngle] = useState(0);
  const handleCloseTwo = () => {
    setShowTwo(false);
    // setRotationAngle(0);
  };
  const handleShowTwo = () => setShowTwo(true);
  const { t } = useTranslation();

  //   const handleRotate = () => {
  //     setRotationAngle(90);
  //     const fullModal = document.querySelector('div .modal-dialog')
  //     console.log(fullModal)
  //     if (fullModal) {
  //       fullModal.style.transform = `rotate(${rotationAngle}deg) !important`;
  //     }
  //   };

  return (
    <div key={image + imageIndex + "img"} style={{ maxHeight: "1000px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          margin: "2% 0%",
          border: "solid 1px white",
        }}>
        <img
          src={image}
          alt={image}
          onClick={handleShowTwo}
          className="project-img"
        />
      </div>
      <p style={{ width: "100%", fontStyle: "italic", fontSize: "14px", textAlign: "center" }}>
        {t("projects.project" + projectNumber + ".image" + imageIndex)}
      </p>
      <Modal
        show={showTwo}
        className={`modal-dialog-scrollable modal-for-image`}
        onHide={handleCloseTwo}>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          >
          <button
            onClick={handleCloseTwo}
            className="btn-close btn-close-white"
            style={{
              position: "absolute",
              top: "2%",
              right: "2%",
            }}
          />
          <img
            src={image}
            alt={image}
            onClick={handleShowTwo}
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProjectCardImage;
