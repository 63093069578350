import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
// import headerImg from "../assets/space/header-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useTranslation } from "react-i18next";

export const Hero = () => {
  const { t } = useTranslation();
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = ["React", "TypeScript"];
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(100 - Math.random() * 100);
  const period = 1500;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 3);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(100);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <>
              <span className="tagline">{t("hero.hello")}</span>
              <TrackVisibility partialVisibility>
                {({ isVisible }) => (
                  <h1>
                    {t("hero.title") === "Software Engineer" ? (
                      <div className="wrap">
                        {isVisible && text}
                        <br />
                        {` ${t("hero.title")}`}
                      </div>
                    ) : (
                      <div className="wrap">
                        {`${t("hero.title")} `}
                        <br />
                        {isVisible && text}
                      </div>
                    )}
                  </h1>
                )}
              </TrackVisibility>
              <p>{t("hero.description")}</p>
              <button onClick={() => (window.location.href = "#contact")}>
                {t("hero.connect")} <ArrowRightCircle size={25} />
              </button>
            </>
          </Col>
          <Col className="image-col" xs={12} md={6} xl={5}>
            {/* <img src={headerImg} alt="Header Img" loading="lazy" /> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
