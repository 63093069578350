
// images technos
import {
    Javascript,
    Typescript,
    MongoDB,
    Nodejs,
    Reactjs,
    Nextjs,
    Tailwind,
} from "../assets/logos/index"


class Technology {
    constructor(name, icon) {
        this.name = name;
        this.icon = icon;
    }
}

export const skills = [
    new Technology("Javascript", Javascript),
    new Technology("Typescript", Typescript),
    new Technology("Nextjs", Nextjs),
    new Technology("React", Reactjs),
    new Technology("Tailwind", Tailwind),
    new Technology("Nodejs", Nodejs),
    new Technology("MongoDB", MongoDB),
]