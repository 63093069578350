import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import gitHubIcon from "../assets/icons/github-white.svg";
import whatsappIcon from "../assets/icons/whatsapp.svg";
import linkedinIcon from "../assets/icons/linkedIn.svg";
import codepenIcon from "../assets/icons/codepen.svg";
import LanguageToggle from "./LanguageToggle";
import { useTranslation } from "react-i18next";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };
 
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Toggle
          className="navbar-toggle"
          aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LanguageToggle />
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a
                className="github"
                href="https://github.com/DylanP97?tab=repositories">
                <img src={gitHubIcon} alt="" />
              </a>
              <a className="whatsapp" href="https://wa.me/+33788485314">
                <img src={whatsappIcon} alt="" />
              </a>
              <a
                className="linkedin"
                href="https://www.linkedin.com/in/dylan-p-973394274/">
                <img src={linkedinIcon} alt="" />
              </a>
              <a
                className="codepen"
                href="https://codepen.io/dydypss/pens/public">
                <img src={codepenIcon} alt="" />
              </a>
            </div>
            <button
              className={"vvd"}
              onClick={() => (window.location.href = "#contact")}>
              <span>{t("hero.connect")}</span>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
