import {
  image1project0,
  image2project0,
  image4project0,
  image5project0,
  image6project0,
  image7project0,
  image1project1,
  image2project1,
  image3project1,
  image4project1,
  image5project1,
  image6project1,
  image1project2,
  image2project2,
  image3project2,
  image4project2,
} from "../assets/images-projects/index.js";
export const myProjects = [
  {
    index: 0,
    title: "",
    difficulty: 2,
    mission: true,
    features: 14,
    info: "",
    images: [
      image1project0,
      image2project0,
      image4project0,
      image5project0,
      image6project0,
      image7project0,
    ],
    visit: "https://apprentissagedelaconduite.fr",
    technologies:
      "React, TypeScript, Next 13, Next API Routes, NextAuth, Prisma, Nodejs, MongoDB, Stripe API, GoogleOauth API, Postmark, Imgbb, Cloudinary, Quill, SASS, Bootstrap, Vercel, Jest, React Testing Library",
    repo: "https://github.com/DylanP97/next13-apprentissage-de-la-conduite",
  },
  {
    index: 1,
    title: "",
    difficulty: 0,
    mission: true,
    features: 6,
    images: [
      image1project1,
      image2project1,
      image3project1,
      image4project1,
      image5project1,
      image6project1,
    ],
    visit: "http://proxima-multiservices.com",
    technologies:
      "React, TypeScript, Next 13, Framer Motion, Tailwind CSS, FormSubmit, React Multi Carousel",
    repo: "https://github.com/DylanP97/next13-proxima-multiservices",
  },
  {
    index: 2,
    title: "",
    difficulty: 0,
    mission: true,
    features: 0,
    images: [
      image1project2,
      image2project2,
      image3project2,
      image4project2,
    ],
    visit: "",
    technologies:
      "React, React Native, Expo, JavaScript, Redux, NativeWind, Tailwind CSS, Axios",
    repo: "https://github.com/DylanP97/native-sakurahouse",
  },
];