import React from 'react'

const SkillCard = ({name, icon}) => {
  return (
    <div className="skill-card" >
      <img src={icon} alt=""/>
    </div>
  )
}

export default SkillCard