import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import astronaut from "../assets/space/astronaut.png";
import { useTranslation } from "react-i18next";

export const Contact = () => {
  const { t } = useTranslation();

  return (
    <section className="contact" id="contact">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            {/* <img
              className="image-col background-image-left"
              src={astronaut}
              alt="background-left"
            /> */}
          </Col>
          <Col md={6}>
            <h2>{t('contact.beforeLanding')}</h2>
            <form
              action="https://formsubmit.co/dylan.pinheiro.1997@gmail.com"
              method="POST">
              <Row>
                <input type="hidden" name="_subject" value="New submission!" />

                <Col sm={6} className="px-1">
                  <input
                    type="text"
                    name="firstName"
                    placeholder={t('contact.firstName')}
                    required
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    type="text"
                    name="lastName"
                    placeholder={t('contact.lastName')}
                    required
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    type="email"
                    name="email"
                    placeholder={t('contact.email')}
                    required
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input type="tel" name="tel" placeholder={t('contact.phone')} />
                </Col>
                <Col className="px-1">
                  <textarea
                    name="message"
                    placeholder={t('contact.message')}
                    required></textarea>
                  <button type="submit">
                    <span>{t('contact.send')}</span>
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
