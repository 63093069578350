"use client";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronUp } from "react-icons/fa";

const Tab = ({ children, index, projectsSectionRef }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const handleTabClick = () => {
    setIsOpen(!isOpen);
    if (projectsSectionRef.current) {
      projectsSectionRef.current.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        borderBottomStyle: "solid",
        borderBottomWidth: isOpen ? "4px" : "2px",
        borderBottomColor: "white",
      }}>
      <div
        style={{
          cursor: "pointer",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: isOpen ? "bold" : "normal",
          transition: "1s ease-in-out",
        }}
        onClick={handleTabClick}>
        <h2
          style={{
            fontSize: "1.4rem",
            fontWeight: "medium",
            margin: "0% 4%"
          }}>
          {t(`projects.project${index}.name`)}
        </h2>
        <div>
          <FaChevronUp
            color="white"
            size={30}
            style={
              isOpen
                ? { transform: "rotate(0deg)", transition: "1s ease-in-out" }
                : { transform: "rotate(0.5turn)", transition: "1s ease-in-out" }
            }
          />
        </div>
      </div>
      <div className={isOpen ? "parent-tab open" : "parent-tab"}>
        {children}
        <div
          style={{
            cursor: "pointer",
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: isOpen ? "bold" : "normal",
          }}
          onClick={handleTabClick}>
          <h2
            style={{
              fontSize: "1rem",
              fontStyle: "italic",
              margin: "0%"
            }}>
            {t(`projects.closemodal`)}
          </h2>
          <FaChevronUp color="white" size={30} />
        </div>
      </div>
    </div>
  );
};

export default Tab;
