import React from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import ProjectCardImage from "./ProjectCardImage";

const ProjectContent = ({
  index,
  mission,
  features,
  visit,
  repo,
  technologies,
  images,
}) => {
  const { t } = useTranslation();

  const responsive = {
    large: {
      breakpoint: { max: 4640, min: 2000 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 1999, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="proj-txtx">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
        className="headband">
        <p>{mission && t(`projects.project${index}.mission`)}</p>
        <p>{technologies}</p>
      </div>
      <div className="layout-second-container">
        <div className="second-container-img">
          {images ? (
            <Carousel
              responsive={responsive}
              infinite={true}
              draggable={false}
              autoPlay={false}
              autoPlaySpeed={1000}
              ssr={true}
              arrows={true}>
              {images &&
                images.map((image, imageIndex) => {
                  return (
                    <ProjectCardImage
                      key={image + imageIndex + "pcimg"}
                      image={image}
                      imageIndex={imageIndex}
                      projectNumber={index}
                    />
                  )
                })}
            </Carousel>
          ) : null}
        </div>
        <div className="second-container-text">
          <p
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: 'start',
              height: "100%",
              width: "100%",
              padding: "1%"
            }}>
            {features !== 0 && <p>{t("projects.features")}:</p> &&
              Array.from({ length: features }, (_, featureIndex) => (
                <li style={{
                    listStyleType: "none",
                    textAlign: "start",
                    margin: "5px 0"
                }} key={index + featureIndex + "feature"}>
                  {t(`projects.project${index}.feature${featureIndex}`)}
                </li>
              ))}
          </p>
        </div>
      </div>
      <div className="btns">
        {visit && (
          <button onClick={() => window.open(visit)}>
            <span>{t("projects.visitnow")}</span>
          </button>
        )}        
      </div>
    </div>
  )
}

export default ProjectContent;
