import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";

function LanguageToggle() {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);


  const width = window.screen.width;

  const isSmallScreen = width > '1400'

  const addAnimation = () => {
    document.body.classList.add("overlay-active");
    document.body.classList.remove("overlay-inactive");
  };

  const removeAnimation = () => {
    setTimeout(() => {
      document.body.classList.remove("overlay-active");
      document.body.classList.add("overlay-inactive");
    }, 1000);
  };

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    addAnimation();
    setTimeout(() => {
      i18n.changeLanguage(newLanguage);
      setCurrentLanguage(newLanguage);
    }, 1000);
    removeAnimation();
  };

  return (
    <Nav.Link
      className={"navbar-link"}
      onClick={toggleLanguage}>
      {currentLanguage === "en" ? (isSmallScreen ? "🇫🇷" : "Français ? 🇫🇷") : (isSmallScreen ? "🇬🇧" : "English? 🇬🇧")}
    </Nav.Link>
  );
}

export default LanguageToggle;
